@font-face {
  font-family: 'Helvetica Now Display';
  font-weight: 700; /* Bold */
  font-style: normal;
  font-display: swap; /* Choose the appropriate font-display value */

  /* Link to your WOFF2 font file for Bold */
  src: url('./fonts/Helvetica-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: 500; /* Bold */
  font-style: normal;
  font-display: swap; /* Choose the appropriate font-display value */

  /* Link to your WOFF2 font file for Bold */
  src: url('./fonts/SF-Pro-Rounded-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Now Display';
  font-weight: 500; /* Medium */
  font-style: normal;
  font-display: swap; /* Choose the appropriate font-display value */

  /* Link to your WOFF2 font file for Medium */
  src: url('./fonts/Helvetica-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Now Display';
  font-weight: 900; /* Extra Black */
  font-style: normal;
  font-display: swap; /* Choose the appropriate font-display value */

  /* Link to your WOFF2 font file for Extra Black */
  src: url('./fonts/Helvetica-XBlack.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Now Display';
  font-weight: 800; /* Extra Bold */
  font-style: normal;
  font-display: swap; /* Choose the appropriate font-display value */

  /* Link to your WOFF2 font file for Extra Bold */
  src: url('./fonts/Helvetica-XBold.woff2') format('woff2');
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  overflow-x: hidden;
  user-select: none;
}
body {
  overflow-x: hidden;
  user-select: none;
  margin: 0;
  font-family:
    'Helvetica Now Display',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
}

.faq-item {
  cursor: pointer;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
}

.faq-item.active {
  transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-color: #cbd5e0;
}

.faq-answer {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  display: none;
}

.faq-item.active + .faq-answer {
  display: block;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.faq-answer.active {
  max-height: 1000px; /* Adjust this value as needed */
}

.fade-out {
  transition: opacity 1s ease-in;
  opacity: 0;
}

.drop-shadow {
  border-radius: 20px;
  box-shadow: 0px 8px 30px 0px rgba(121, 160, 134, 0.8);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes at least the full viewport height */
}

.video-container {
  flex: 1; /* Allow the video container to expand to fill available space */
}

.content-container {
  flex: 1; /* Allow the content container to expand to fill available space */
}
